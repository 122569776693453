import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CollectionsIcon from '@mui/icons-material/Collections';
import '../../styles/BottomNavigation.css';

const BottomNavigation = () => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const navItems = [
    { path: '/', icon: HomeIcon, label: 'Home' },
    { path: '/games', icon: SportsEsportsIcon, label: 'Games' },
    { path: '/tasks', icon: AssignmentIcon, label: 'Tasks' },
    { path: '/leaderboard', icon: LeaderboardIcon, label: 'Leaderboard' },
    { path: '/wallet', icon: AccountBalanceWalletIcon, label: 'Wallet' },
    { path: '/nfts', icon: CollectionsIcon, label: 'NFTs' },
  ];

  return (
    <nav className="bottom-nav">
      {navItems.map((item) => {
        const IconComponent = item.icon;
        return (
          <Link
            key={item.path}
            to={item.path}
            className={`bottom-nav-item ${isActive(item.path) ? 'active' : ''}`}
          >
            <IconComponent />
            {isActive(item.path) && <span className="nav-label">{item.label}</span>}
          </Link>
        );
      })}
    </nav>
  );
};

export default BottomNavigation;