import React from 'react';
import NFTDetails from './NFTDetails';

const MiniverseNFT = () => {
  const nfts = [
    { id: 1, name: 'Miniverse Lite', price: 10, image: '/path/to/lite-image.jpg' },
    { id: 2, name: 'Miniverse Pro', price: 100, image: '/path/to/pro-image.jpg' },
  ];

  return (
    <div className="miniverse-nft">
      <section className="hero">
        <h1>Miniverse NFTs</h1>
        <p>Discover unique digital assets in the Miniverse ecosystem</p>
      </section>

      <div className="nft-grid">
        {nfts.map((nft) => (
          <NFTDetails key={nft.id} nft={nft} />
        ))}
      </div>
    </div>
  );
};

export default MiniverseNFT;