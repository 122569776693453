import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { UserProvider, useUser } from './contexts/UserContext';
import { AppProvider, useAppContext } from './contexts/AppContext';
import BottomNavigation from './components/Layout/BottomNavigation';
import Home from './pages/Home';
import Games from './pages/Games';
import Tasks from './pages/Tasks';
import Leaderboard from './pages/Leaderboard';
import Wallet from './pages/Wallet';
import EdgewareWallet from './pages/EdgewareWallet';
import NFTs from './pages/NFTs';
import SplashScreen from './components/Layout/SplashScreen';
import { initializeUser, setTelegramInitData } from './services/api';
import './styles/global.css';

// Import individual game components
import LionGame from './components/Games/LionGame';
import RabbitGame from './components/Games/RabbitGame';
import ShyBirdGame from './components/Games/ShyBirdGame';

function AppContent() {
  const [initializing, setInitializing] = useState(true);
  const [initError, setInitError] = useState(null);
  const { setUser } = useUser();
  const { isGlobalLoading, startGlobalLoading, stopGlobalLoading } = useAppContext();

  useEffect(() => {
    const tgWebApp = window.Telegram.WebApp;
    tgWebApp.ready();
    tgWebApp.expand();

    const initApp = async () => {
      try {
        if (!tgWebApp.initDataUnsafe || !tgWebApp.initDataUnsafe.user) {
          throw new Error('Telegram Web App data is not available');
        }
        setTelegramInitData(tgWebApp.initData);
        const userData = await initializeUser({
          telegramInitData: tgWebApp.initData,
          telegram_id: tgWebApp.initDataUnsafe.user.id,
          referral_code: new URLSearchParams(window.location.search).get('ref')
        });
        setUser(userData.user);
      } catch (error) {
        console.error('Failed to initialize user:', error);
        setInitError(error.message);
      } finally {
        setInitializing(false);
      }
    };

    initApp();
  }, [setUser]);

  if (initializing) {
    return <SplashScreen />;
  }

  if (initError) {
    return <div>Error: {initError}</div>;
  }

  return (
    <div className="App">
      {isGlobalLoading && <div className="global-loader">Loading...</div>}
      <main className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/games" element={<Games />} />
          <Route path="/games/lion" element={<LionGame />} />
          <Route path="/games/rabbit" element={<RabbitGame />} />
          <Route path="/games/shybird" element={<ShyBirdGame />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/edgeware" element={<EdgewareWallet />} />
          <Route path="/nfts" element={<NFTs />} />
        </Routes>
      </main>
      <BottomNavigation />
    </div>
  );
}

function App() {
  return (
    <TonConnectUIProvider manifestUrl={`${process.env.REACT_APP_URL}/tonconnect-manifest.json`}>
      <UserProvider>
        <AppProvider>
          <Router>
            <AppContent />
          </Router>
        </AppProvider>
      </UserProvider>
    </TonConnectUIProvider>
  );
}

export default App;