import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TonConnectButton, useTonConnectUI } from '@tonconnect/ui-react';
import { FaWallet, FaGift, FaGamepad, FaUserFriends, FaTasks, FaChartLine, FaClock, FaExchangeAlt } from 'react-icons/fa';
import linesImage from '../styles/img/lines.png';
import tonLogoImage from '../styles/img/ton-logo.png';
import '../styles/Wallet.css';

const Wallet = () => {
  const [tonConnectUI] = useTonConnectUI();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  const navigateToEdgewareWallet = () => {
    navigate('/edgeware');
  };

  return (
    <div className="screen-3">
      <section className="frame-parent">
        <img className="frame-child" alt="" src={linesImage} />
        <div className="wallet-tab-menu">
          <button className="active">
            <FaWallet /> TON Wallet
          </button>
          <button onClick={navigateToEdgewareWallet}>
            <FaExchangeAlt /> Edgeware Wallet
          </button>
        </div>
        <div className="frame-item">
          <img src={tonLogoImage} alt="TON Logo" className="ton-logo-image" />
          <div className="card-content">
            <div className="card-top">
              <div className="ton-logo">TON Wallet</div>
              <div className="connect-button-wrapper">
                <TonConnectButton />
              </div>
            </div>
            <div className="card-bottom">
              {tonConnectUI.wallet && (
                <div className="wallet-provider">
                  <img src={tonConnectUI.wallet.imageUrl} alt={`${tonConnectUI.wallet.name} logo`} className="provider-logo" />
                  <span className="provider-name">{tonConnectUI.wallet.name}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="airdrop-eligibility">
        <h2 className="airdrop-title"><FaGift /> Airdrop Eligibility</h2>
        <div className="eligibility-grid">
          <div className="rumor-source">
            <div className="rumor-topic">
              <FaGamepad className="rumor-2-icon" color='#f6d83e'/>
            </div>
            <div className="inspiration-request">
              <div className="i-need-some">Play Games</div>
              <p>You reached 80% of your gaming goal</p>
            </div>
          </div>
          <div className="rumor-source">
            <div className="rumor-topic">
              <FaUserFriends className="rumor-2-icon" color='#c699ff' />
            </div>
            <div className="inspiration-request">
              <div className="i-need-some">Invite Friends</div>
              <p>You invited 5 friends this week</p>
            </div>
          </div>
          <div className="rumor-source">
            <div className="rumor-topic">
              <FaTasks className="rumor-2-icon" color='#81d389' />
            </div>
            <div className="inspiration-request">
              <div className="i-need-some">Complete Tasks</div>
              <p>You completed 8/10 daily tasks</p>
            </div>
          </div>
          <div className="rumor-source">
            <div className="rumor-topic">
              <FaChartLine className="rumor-2-icon" color='#f6d83e' />
            </div>
            <div className="inspiration-request">
              <div className="i-need-some">On-chain activities</div>
              <p>Perform transactions on TON</p>
            </div>
          </div>
          <div className="rumor-source">
            <div className="rumor-topic">
              <FaClock className="rumor-2-icon" color='#c699ff' />
            </div>
            <div className="inspiration-request">
              <div className="i-need-some">Coming soon</div>
              <p>More ways to earn coming soon!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;