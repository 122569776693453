import React, { useState, useEffect } from 'react';
import { getUserNFTs } from '../../services/nftService';

const MyNFTs = () => {
  const [nfts, setNFTs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const userNFTs = await getUserNFTs();
        setNFTs(userNFTs);
      } catch (error) {
        console.error('Error fetching user NFTs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, []);

  if (loading) {
    return <div>Loading your NFTs...</div>;
  }

  if (nfts.length === 0) {
    return <div>You don't own any NFTs yet.</div>;
  }

  return (
    <div className="my-nfts">
      <h2>My NFT Collection</h2>
      <div className="nft-grid">
        {nfts.map((nft) => (
          <div key={nft.id} className="nft-item">
            <img src={nft.image} alt={nft.name} />
            <h3>{nft.name}</h3>
            <p>ID: {nft.id}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyNFTs;