import React, { useState } from 'react';
import { buyNFT } from '../../services/nftService';

const NFTDetails = ({ nft }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleBuy = async () => {
    try {
      await buyNFT(nft.id);
      alert('NFT purchased successfully!');
    } catch (error) {
      console.error('Error buying NFT:', error);
      alert('Failed to purchase NFT. Please try again.');
    }
  };

  return (
    <div className="nft-details">
      <img src={nft.image} alt={nft.name} />
      <h3>{nft.name}</h3>
      <p>Price: ${nft.price}</p>
      <div className="nft-actions">
        <button onClick={handleBuy}>Buy</button>
        <button onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? 'Hide Details' : 'Show Details'}
        </button>
      </div>
      {showDetails && (
        <div className="nft-benefits">
          <h4>Benefits:</h4>
          <ul>
            <li>Exclusive access to Miniverse events</li>
            <li>Special in-game items</li>
            <li>Voting rights in Miniverse DAO</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NFTDetails;