import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MiniverseNFT from '../components/NFTs/MiniverseNFT';
import MyNFTs from '../components/NFTs/MyNFTs';
import '../styles/NFTs.css';

const NFTs = () => {
  const [activeTab, setActiveTab] = useState('miniverse');

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  return (
    <div className="nfts-page">
      <div className="tab-menu">
        <button 
          className={`tab-button ${activeTab === 'miniverse' ? 'active' : ''}`} 
          onClick={() => setActiveTab('miniverse')}
        >
          Miniverse NFT
        </button>
        <button 
          className={`tab-button ${activeTab === 'my-nfts' ? 'active' : ''}`} 
          onClick={() => setActiveTab('my-nfts')}
        >
          My NFTs
        </button>
      </div>
      
      {activeTab === 'miniverse' ? <MiniverseNFT /> : <MyNFTs />}
      
      {activeTab === 'my-nfts' && (
        <div className="wallet-link">
          <Link to="/edgeware">Visit wallet page to check Transactions history</Link>
        </div>
      )}
    </div>
  );
};

export default NFTs;