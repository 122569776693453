import React, { useState } from 'react';
import { TelegramIcon, XIcon, FacebookIcon, WhatsAppIcon, CopyIcon, CheckIcon } from './Icons';
import { FaGift } from "react-icons/fa6";
import '../../styles/ShareDialog.css';

const ShareDialog = ({ isOpen, onClose, inviteLink, inviteMessage }) => {
  const [copied, setCopied] = useState(false);

  if (!isOpen) return null;

  const shareOptions = [
    { 
      name: 'Telegram', 
      icon: <TelegramIcon />, 
      action: () => {
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.openTelegramLink(`https://t.me/share/url?text=${encodeURIComponent(inviteMessage)}`);
        } else {
          console.error('Telegram WebApp is not available');
        }
      }
    },
    { 
      name: 'Copy', 
      icon: copied ? <CheckIcon /> : <CopyIcon />, 
      action: () => {
        navigator.clipboard.writeText(inviteLink).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        });
      }
    },
    { 
      name: 'WhatsApp', 
      icon: <WhatsAppIcon />, 
      action: () => window.Telegram.WebApp.openLink(`https://wa.me/?text=${encodeURIComponent(inviteMessage)}`)
    },
    { 
      name: 'X', 
      icon: <XIcon />, 
      action: () => window.Telegram.WebApp.openLink(`https://twitter.com/intent/tweet?text=${encodeURIComponent(inviteMessage)}`)
    },
    { 
      name: 'Facebook', 
      icon: <FacebookIcon />, 
      action: () => window.Telegram.WebApp.openLink(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(inviteLink)}`)
    },
  ];

  return (
    <div className="share-dialog-overlay">
      <div className="share-dialog">
        <div className='gift'>
          <FaGift color={'orange'} size={40} />
        </div>
        <h2>Invite & Earn</h2>
        <div className="invite-details">
          <p>Invite friends, get rewards:</p>
          <ul>
		    <li>5 passes & 15,000 coins for Premium users</li>
            <li>2 passes & 10,000 coins per friend</li>
          </ul>
        </div>
        <div className="share-options">
          {shareOptions.map((option, index) => (
            <button key={index} onClick={option.action} className="share-option">
              <span className="share-icon">{option.icon}</span>
              <span>{option.name}</span>
            </button>
          ))}
        </div>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default ShareDialog;