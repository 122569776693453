import React, { useState, useEffect } from 'react';
import { checkDailyBonus, claimDailyBonus } from '../../services/api';
import { FaTrophy, FaTimes, FaCoins, FaTicketAlt } from 'react-icons/fa';
import confetti from 'canvas-confetti';
import '../../styles/DailyBonusPopup.css';

const DailyBonusPopup = ({ onClose, onClaim }) => {
  const [bonusData, setBonusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [claiming, setClaiming] = useState(false);

  useEffect(() => {
    const fetchDailyBonus = async () => {
      try {
        const data = await checkDailyBonus();
        setBonusData(data);
      } catch (error) {
        console.error('Error checking daily bonus:', error);
        setError('Failed to check daily bonus. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchDailyBonus();
  }, []);

  const handleClaim = async () => {
    if (claiming) return;
    
    setClaiming(true);
    try {
      const data = await claimDailyBonus();
      if (data.bonusClaimed) {
        setBonusData(data);
        onClaim(data.newCoins, data.newPasses);
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 }
        });
      } else {
        setError(data.message || 'Failed to claim bonus. Please try again.');
      }
    } catch (error) {
      console.error('Error claiming daily bonus:', error);
      setError('Failed to claim bonus. Please try again.');
    } finally {
      setClaiming(false);
    }
  };

  if (loading) {
    return (
      <div className="daily-bonus-popup">
        <div className="bonus-card">
          <div className="loading">Loading...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="daily-bonus-popup">
        <div className="bonus-card">
          <div className="error-message">
            <h2>Error</h2>
            <p>{error}</p>
            <button className="claim-button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!bonusData || !bonusData.eligible) {
    return null;
  }

  return (
    <div className="daily-bonus-popup">
      <div className="bonus-card">
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="bonus-icon">
          <FaTrophy />
        </div>
        <h2 className="bonus-title">Daily Bonus</h2>
        <p className="bonus-subtitle">Claim your daily rewards:</p>
        <div className="bonus-rewards">
          <div className="reward-item">
            <FaCoins className="reward-icon coins" />
            <span className="reward-value">10,000</span>
            <span className="reward-label">coins</span>
          </div>
          <div className="reward-item">
            <FaTicketAlt className="reward-icon passes" />
            <span className="reward-value">5</span>
            <span className="reward-label">passes</span>
          </div>
        </div>
        <button 
          className="claim-button" 
          onClick={handleClaim}
          disabled={claiming}
        >
          {claiming ? 'Claiming...' : 'Claim Bonus'}
        </button>
      </div>
    </div>
  );
};

export default DailyBonusPopup;