import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from '../contexts/AppContext';
import { getTasks, startTask, claimTask, retryTask } from '../services/api';
import { FaBolt } from 'react-icons/fa';
import '../styles/Tasks.css';

const CATEGORIES = ['Socials', 'OnChain', 'Partners', 'Bonus'];

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All');
  const [loadingTaskId, setLoadingTaskId] = useState(null);
  const [claimableTaskId, setClaimableTaskId] = useState(null);
  const [completedTasks, setCompletedTasks] = useState(0);
  const [totalTasks, setTotalTasks] = useState(0);
  const [coinsEarned, setCoinsEarned] = useState(0);
  const { startLoading, stopLoading, setError } = useAppContext();

  const fetchTasks = useCallback(async () => {
    startLoading();
    try {
      const tasksData = await getTasks();
      setTasks(tasksData);
      setTotalTasks(tasksData.length);
      setCompletedTasks(tasksData.filter(task => task.status === 'completed').length);
      setCoinsEarned(tasksData.reduce((sum, task) => task.status === 'completed' ? sum + task.reward : sum, 0));
    } catch (error) {
      setError('Failed to fetch tasks. Please try again.');
    } finally {
      stopLoading();
    }
  }, [startLoading, stopLoading, setError]);

  useEffect(() => {
    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.setHeaderColor('#000000');
    }
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    const inProgressTasks = tasks.filter(task => task.status === 'in_progress');
    inProgressTasks.forEach(task => {
      if (!claimableTaskId && loadingTaskId !== task.id) {
        setClaimableTaskId(task.id);
      }
    });
  }, [tasks, claimableTaskId, loadingTaskId]);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const openLinkNatively = useCallback((url) => {
    if (!isValidUrl(url)) {
      setError('Invalid URL provided.');
      return;
    }

    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.ready();
        if (url.startsWith('https://t.me/') || url.startsWith('tg://')) {
          window.Telegram.WebApp.openTelegramLink(url);
        } else {
          window.Telegram.WebApp.openLink(url);
        }
      } else {
        window.open(url, '_blank');
      }
    } catch (error) {
      setError('Error opening link. It has been opened in a new tab.');
      window.open(url, '_blank');
    }
  }, [setError]);

  const handleTaskAction = useCallback(async (task, action) => {
    startLoading();
    try {
      let result;
      switch (action) {
        case 'start':
        case 'retry':
          if (task.url) {
            openLinkNatively(task.url);
          }
          result = action === 'start' ? await startTask(task.id) : await retryTask(task.id);
          if (result.success) {
            setLoadingTaskId(task.id);
            setTimeout(() => {
              setLoadingTaskId(null);
              setClaimableTaskId(task.id);
            }, 60000); // 60 seconds timer
          }
          break;
        case 'claim':
          result = await claimTask(task.id);
          if (result.success) {
            setClaimableTaskId(null);
          }
          break;
        default:
          throw new Error('Invalid action');
      }

      if (result.success) {
        await fetchTasks(); // Refetch tasks to get updated status
      } else {
        setError(result.message || `Failed to ${action} task. Please try again.`);
      }
    } catch (error) {
      setError(`Failed to ${action} task. Please try again.`);
    } finally {
      stopLoading();
    }
  }, [startLoading, stopLoading, setError, fetchTasks, openLinkNatively]);

  const renderTaskItem = useCallback((task) => (
    <div key={task.id} className="task-item">
      <div className="task-reward">
        <FaBolt />
        <span>{task.reward}</span>
      </div>
      <div className="task-content">
        <h3>{task.description}</h3>
        <p>{task.category}</p>
      </div>
      <div className="task-actions">
        {task.status === 'not_started' && (
          <button 
            onClick={() => handleTaskAction(task, 'start')}
            className="task-action-button start"
          >
            Start
          </button>
        )}
        {task.status === 'in_progress' && (
          <button 
            onClick={() => handleTaskAction(task, 'claim')}
            className="task-action-button claim"
            disabled={loadingTaskId === task.id || (claimableTaskId !== task.id && loadingTaskId !== null)}
          >
            {loadingTaskId === task.id ? (
              <div className="blinking-spinner">
                <div className="blinking-dot"></div>
                <div className="blinking-dot"></div>
                <div className="blinking-dot"></div>
              </div>
            ) : 'Claim'}
          </button>
        )}
        {task.status === 'failed' && (
          <button 
            onClick={() => handleTaskAction(task, 'retry')}
            className="task-action-button retry"
          >
            Retry
          </button>
        )}
        {task.status === 'completed' && (
          <div className="task-status completed">
            <span className="checkmark">✓</span>
          </div>
        )}
      </div>
      {task.is_new && <span className="new-badge">New</span>}
    </div>
  ), [handleTaskAction, loadingTaskId, claimableTaskId]);

  const filteredTasks = tasks.filter(task => 
    activeCategory === 'All' || 
    (activeCategory === 'New' ? task.is_new : task.category === activeCategory)
  );

  const progressPercentage = (completedTasks / totalTasks) * 100;

  return (
    <div className="tasks-page">
      <div className="tasks-header">
        <div className="progress-circle">
          <svg viewBox="0 0 36 36" className="circular-chart">
            <path className="circle-bg"
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path className="circle"
              strokeDasharray={`${progressPercentage}, 100`}
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <div className="progress-content">
            <div className="progress-icon">⚡</div>
            <div className="progress-number">{completedTasks}</div>
            <div className="progress-total">/{totalTasks}</div>
          </div>
        </div>
        <div className="coins-earned">
          <span>{coinsEarned}</span>
          <span>Coins Earned</span>
        </div>
      </div>

      <div className="category-tabs">
        <button
          className={`category-tab ${activeCategory === 'All' ? 'active' : ''}`}
          onClick={() => setActiveCategory('All')}
        >
          All
        </button>
        <button
          className={`category-tab ${activeCategory === 'New' ? 'active' : ''}`}
          onClick={() => setActiveCategory('New')}
        >
          New
        </button>
        {CATEGORIES.map(category => (
          <button
            key={category}
            className={`category-tab ${activeCategory === category ? 'active' : ''}`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <div className="task-list">
        {filteredTasks.map(renderTaskItem)}
      </div>
    </div>
  );
};

export default Tasks;